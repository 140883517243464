// Generated by Framer (236a21b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["EUKHn3K0i"];

const serializationHash = "framer-gVEXR"

const variantClassNames = {EUKHn3K0i: "framer-v-1iiceeq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({color, height, id, width, ...props}) => { return {...props, f9W3_FPaG: color ?? props.f9W3_FPaG ?? "rgb(255, 255, 255)"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, f9W3_FPaG, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "EUKHn3K0i", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1iiceeq", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"EUKHn3K0i"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0V1Y2xpZCBDaXJjdWxhciBBIEJvbGQ=", "--framer-font-family": "\"Euclid Circular A Bold\", \"Euclid Circular A Bold Placeholder\", sans-serif", "--framer-font-size": "25px", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-f9W3_FPaG-DcSgQqg05))"}}>Halogen</motion.p></React.Fragment>} className={"framer-15cykkf"} fonts={["CUSTOM;Euclid Circular A Bold"]} layoutDependency={layoutDependency} layoutId={"u3xJbD5_D"} style={{"--extracted-r6o4lv": "var(--variable-reference-f9W3_FPaG-DcSgQqg05)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-f9W3_FPaG-DcSgQqg05": f9W3_FPaG}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gVEXR.framer-226742, .framer-gVEXR .framer-226742 { display: block; }", ".framer-gVEXR.framer-1iiceeq { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-gVEXR .framer-15cykkf { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gVEXR.framer-1iiceeq { gap: 0px; } .framer-gVEXR.framer-1iiceeq > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-gVEXR.framer-1iiceeq > :first-child { margin-left: 0px; } .framer-gVEXR.framer-1iiceeq > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 101
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"f9W3_FPaG":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDcSgQqg05: React.ComponentType<Props> = withCSS(Component, css, "framer-gVEXR") as typeof Component;
export default FramerDcSgQqg05;

FramerDcSgQqg05.displayName = "HalogenLogoFont";

FramerDcSgQqg05.defaultProps = {height: 30, width: 101};

addPropertyControls(FramerDcSgQqg05, {f9W3_FPaG: {defaultValue: "rgb(255, 255, 255)", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerDcSgQqg05, [{explicitInter: true, fonts: [{family: "Euclid Circular A Bold", source: "custom", url: "https://framerusercontent.com/assets/20VCN2TXeaMRCk1AvyVLhIWN9Hg.woff2"}]}], {supportsExplicitInterCodegen: true})